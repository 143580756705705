$(document).ready(function () {

	//Wrap navigation to more dropdown link
	$('#main-header .menu ul').navwrap();


	$("#tabs").tabs();

	$('.alt_propimage').hover(function (e) {
		$(this).parent('.alt_property').find('.alt_description').show();
	});
	$('.alt_description').mouseleave(function (e) {
		$(this).hide();
	});

	//to add more info link //
	$('.alt_property_container').each(function () {
		var link = $(this).find('.alt_propimage a').attr('href');
		$(this).find('.alt_property').append('<a href="' + link + '" class="more-info-link">More info</a>')
	});

	//view images popup
	$(".images_popup").dialog({
		autoOpen: false,
		width: 1000,
		height: 800,
		dialogClass: "popup-images",
		modal: true
	});

	$(".view_images_popup").click(function (event) {
		$(".images_popup").dialog("open");
		event.preventDefault();
	});

	$(".images_popup img").click(function () {
		$(".images_popup").dialog("close");
		$(".bidx_image_main").attr('src', $(this).attr('src'));
	});

	if ($(".agent-list-container").length > 0) {
		$(".agent-list-container").carouFredSel({
			circular: true,
			infinite: false,
			width: 1093,
			height: 420,
			align: "left",
			items: {
				width: 200,
				height: 420
			},
			scroll: {
				items: 1,
				duration: 500,
				pauseOnHover: true
			},
			prev: ".agents-list-block .prev-link",
			next: ".agents-list-block .next-link"
		});
	}

	if ($(".banner-slides").length > 0) {
		$(".banner-slides").carouFredSel({
			circular: true,
			infinite: true,
			width: 353,
			height: 204,
			scroll: {
				fx: 'crossfade'
			},
			pagination: "#slides-page"
		});
	}

	if ($("#tabbed-section .fplist").length > 0) {
		$("#tabbed-section .fplist").carouFredSel({
			circular: true,
			infinite: false,
			width: 1100,
			scroll: {
				items: 1,
				duration: 1500,
				pauseOnHover: true
			},
			prev: ".fplist-container .prev-link",
			next: ".fplist-container .next-link"
		});
	}

	//Tooltips
	$(".favorites_link a").attr("title", "Save this property");
	$(".question_link a").attr("title", "Request Information");
	$(".alt_virtualtour a").attr("title", " Virtual Tour");
	$(".alt_pricedrop").attr("title", "Reduced");
	$(".alt_green a").attr("title", " Green");
	$(".alt_adult a").attr("title", " Adult");
	$(".alt_shortsale a").attr("title", " Shortsale");
	$(".alt_foreclosure a").attr("title", "Foreclosure");
	$(".alt_view_similar a").attr("title", "More Like This");

	$(".favorites_link a, .question_link a, .alt_virtualtour a, .alt_pricedrop, .alt_green a, .alt_adult a, .alt_shortsale a, .alt_foreclosure a, .alt_view_similar a").tooltip({
		position: {
			my: "center bottom-20",
			at: "center top",
			using: function (position, feedback) {
				$(this).css(position);
				$("<div>")
					   .addClass("arrow")
					   .addClass(feedback.vertical)
					   .addClass(feedback.horizontal)
					   .appendTo(this);
			}
		}
	});

	// Code for print css
	// Clone detail page comments
	var detailComment = $(".detail_comment li").clone();
	var detailCommentprint = $(".detail_comment li").clone();
	var commentTitle = $(".detail-comment-title").clone();
	$(".detail_comment li").remove();
	$(detailComment).insertAfter($("#detailprop")).addClass("detailComment");
	$(detailCommentprint).insertAfter($("#detailprop")).addClass("detailCommentprint");
	$(commentTitle).insertAfter($("#detailprop")).addClass("detailtitle");

	$(".detailtitle").wrap($("<div class='printcomment'></div>"));
	$(".detailCommentprint").appendTo(".printcomment");

	var commentText = $(detailComment).text();
	if (commentText.length > 500) {
		commentText = commentText.slice(0, 500) + " ... ";
		$('.detailCommentprint').text(commentText);
	}

	// add notes heading on flyer
	$(".notes-print").prepend("<h2 class='notes'>Contact Info</h2>");


	// if ( $(".like_login").length == 0 ) {
	// 	console.log("test");
	// 	$(".alt_price").css("left", "45px");
	// }
	//setInterval('cycleImages()', 5000);

	$("li.bidx_image_list img").hover(function (e) {
		$(".bidx_image_main").attr('src', $(this).attr('src'));
	});
});

// Property images carousel
if ($("#tabs-1 .property_img_gallery ol").length > 0) {
	$("#tabs-1 .property_img_gallery ol").carouFredSel({
		align: "left",
		width: 720,
		circular: false,
		infinite: false,
		auto: false,
		items: {
			visible: 5
		},
		prev: ".prev-detail",
		next: ".next-detail"
	});
}

//Propery image gallyer, change image on hover.
$("li.bidx_image_list img").hover(function (e) {
	$(".bidx_image_main").attr('src', $(this).attr('src'));
})

function cycleImages() {
	var $active = $('.banner-image .active');
	var $next = ($active.next().length > 0) ? $active.next() : $('.banner-image div:first');
	$next.css('z-index', 2);//move the next image up the pile
	$active.fadeOut(1500, function () {//fade out the top image
		$active.css('z-index', 1).show().removeClass('active');//reset the z-index and unhide the image
		$next.css('z-index', 3).addClass('active');//make the next image the top one
	});

	$(".banner-search select").dropkick();

}

if ($(".testimonial-list-container").length > 0) {
	$(".testimonial-list-container .testimonial").show();
	$(".testimonial-list-container").carouFredSel({
		circular: true,
		infinite: false,
		align: "left",
		height: 130,
		responsive: true,
		scroll: {
			items: 1,
			duration: 1000
		},
		pagination: "#foo2_pag"
	});
}
