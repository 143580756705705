//code for liked listing box
$(document).ready(function () {
	var debug = false;

	var $list_box = $(".user_liked_listing");
	var $list = $(".user_liked_listing .customer_likes");

	//Match text of liked listing button with box heading
	$('.btn_liked_listing').text($('.user_liked_listing .customer_likes .liked-list-title').text());

	//Slide box from left on page load. Hide Liked listing button
	if (debug)
		console.log($.cookie("liked_listing"));
	if ($.cookie("liked_listing") == "open" || $.cookie("liked_listing") == undefined) {
		$('.btn_liked_listing').hide();
		$list_box.animate({left: '0px'});
	}

	//find and set vertical position of box
	var h1 = $(window).height() / 2
	if (debug)
		console.log('window height: ' + h1);

	var h2 = $list_box.outerHeight() / 2;
	var position = (h1 - h2) + 'px';
	if (debug)
		console.log('position: ' + position);

	$list_box.css({top: position});

	//show Liked listing button only if there are any liked properties
	if ($list.length > 0) {
		if (debug)
			console.log('list length: ' + $list.length);

		if ($.cookie("liked_listing") == "closed") {
			$('.btn_liked_listing').animate({left: '-66px'});
		}

		//Insert close icon
		$list_box.append("<span class='close-listing'></span>");
	}

	//hide box on click of close icon
	$('body').on('click', '.close-listing', function () {
		var width = $list.outerWidth() + 30;
		$list_box.animate({left: -width}, 500, function () {
			$('.btn_liked_listing').animate({left: '-66px'});
		});

		$.cookie("liked_listing", "closed");
	});

	//Show box on click of Liked listing button
	$('.btn_liked_listing').on('click', function () {
		$(this).animate({left: '-106px'}, 500, function () {
			$list_box.animate({left: '0px'});
		});
		$.cookie("liked_listing", "open");
	})
});
